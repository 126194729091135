import firebase from "firebase/app";
// import firebaseAnalitics from "firebase/analytics";
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/storage';

var firebaseConfig = {
   apiKey: "AIzaSyBpU2GFM4cjaSOtxOuudfrO4XnIMBXUvjc",
   authDomain: "paginaggs.firebaseapp.com",
   databaseURL: "https://paginaggs.firebaseio.com",
   projectId: "paginaggs",
   storageBucket: "paginaggs.appspot.com",
   messagingSenderId: "637213630917",
   appId: "1:637213630917:web:9922cfb682dbe5d5cd2bac",
   measurementId: "G-1WH339F5K8"

    // apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}` ,
    // authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    // databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
    // projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    // storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    // messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGINGSID}`,
    // appId: `${process.env.REACT_APP_FIREBASE_APPID}`,
    // measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENTID}`
  };
  

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
 }else {
    firebase.app();
 }

 firebase.analytics();

export const db = firebase.firestore();

export const storage = firebase.storage();