import React, { useEffect, useState } from "react";
import { db, storage } from "../../firebase";
import {
  Row,
  Col,
  Button,
  Card,
  Container,
  Carousel,
  Spinner,
} from "react-bootstrap";

import Nueva from "../../img/gasNueva.jpeg";

function ProductosBanner() {
  const [precios, setPrecios] = useState([]);
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(true);

  let gaso = ["Magna", "Premium"];

  const GetPrices = async () => {
    setLoading(true);

    await db
      .collection("precios")
      .doc("G6KYN5RfGYmS9vADvoCd")
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          console.log(doc.id, "=>", doc.data());
          let docData = doc.data();
          let magna = docData["magna"];
          let premium = docData["prem"];
          let dd = docData["day"];
          let mm = docData["month"];
          let yyyy = docData["year"];
          let dat = docData["date"];

          let prices = [magna, premium];
          setPrecios(prices);
          setDate(dat);
        }

        setLoading(false);
      });
  };

  useEffect(() => {
    GetPrices();
    console.log("collecting...");
  }, []);

  return (
    <div>
      {/* <div className="spacing"></div>

      <h3 className="ml-4">Precios de Gasolina y Productos</h3>
      <p className="ml-4">
        Checa aquí nuestros precios de gasolina y demás productos adicionales.
      </p>
      <div id="introLoc">
        <img src={Nueva} alt="" className="imgCo" />
      </div> */}

      <section
        className="text-center overflow-hidden py-0 prodpgBanner"
        id="top"
        // data-zanim-timeline="{}"
        // data-zanim-trigger="scroll"
      >
        <div className="container-fluid mask-dark">
          <div className="header-overlay"></div>
          <div className="position-relative pt-10 pb-8">
            <div className="header-text">
              <div className="overflow-hidden">
                <h1
                  className="display-3 text-white font-weight-light ls-1"
                  //   data-zanim-xs='{"duration":2,"delay":0}'
                >
                  Productos
                </h1>
              </div>
              <div className="overflow-hidden">
                <div
                  className="d-flex justify-content-center"
                  //   data-zanim-xs='{"duration":2,"delay":0.1}'
                >
                  <p className="text-uppercase ls-1 text-white ">
                    Checa aquí nuestros precios de gasolina y demás productos
                    adicionales.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Container className="mt-4">
        <Row>
          {loading ? (
            <Col lg={3} md={6} className="jcc mb-4">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
              <br />
            </Col>
          ) : (
            precios.map((gas) => {
              return (
                <Col lg={6} md={6} className="jcc">
                  <Card
                    style={{
                      width: "100%",
                      color: "white",
                      borderRadius: "15px",
                      backgroundColor:
                        precios.indexOf(gas) == 0 ? "green" : "red",
                    }}
                    className="mb-4"
                  >
                    <Card.Body className="fdc spb">
                      <div>
                        <Card.Title className="fdr jcc ">
                          <h4 className="text-white font-weight-normal">
                            {gaso[precios.indexOf(gas)]}
                          </h4>
                        </Card.Title>
                        <Card.Text className="fdr jcc mb-2 ">
                          <h3 className="text-white font-weight-normal">
                            ${gas} /lto
                          </h3>
                        </Card.Text>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })
          )}
        </Row>
        <p>Precios actualizados el {date}</p>
      </Container>

      <h3 className="ml-4">Productos Adicionales</h3>
    </div>
  );
}

export default ProductosBanner;
