import React, { Component, useEffect, useState } from "react";

import Navtab from "../components/Navbar/navbar";
import Banner from "../components/landing/banner";
import Intro from "../components/landing/intro";
import Footer from "../components/Footer.js";
import Ad from "../components/ad.js";
import Contact from "../components/landing/contact.js";
import Comments from "../components/landing/comments.js";
import AnimatedSection from "../components/landing/animated.js";
import LealtadSection from "../components/landing/lealtad.js";
import { Modal, Button, Card, Row, Col, Container, Carousel } from "react-bootstrap";

import Addd from "../img/AdMariiachi.png";

import "../App.css";

function Landing() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(false);

  useEffect(() => {
    handleShow();
  }, []);
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="font-weight-semi-bold">
            ¿Eres músico?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <a href="https://mariiachi.com" target="__blank">
            <img src={Addd} style={{ width: "100%", height: "auto" }} />
          </a>
        </Modal.Body>
      </Modal>

      <Navtab />
      <Banner />
      <Intro />
      <AnimatedSection />
      <Comments />
      <br />
      <LealtadSection />

      <Container>
        <Row className="py-5">
          <Col xs={12} lg={6}>
            <div style={{ maxHeight: "27rem", width: "100%", borderRadius: "30px" }}>
              <a href="https://mariiachi.com" target="__blank">
                <img src={Addd} style={{ width: "100%", height: "100%", objectFit: "contain", borderRadius: "30px" }} />
              </a>
            </div>
          </Col>

          <Col xs={12} lg={6}>
            <div className="py-3">
              <p className="fs-2 font-weight-bold">Orgulloso patrocinador de <a href="https://mariiachi.com" target="__blank" className="text-mariachi">mariiachi.com</a></p>

              <p className="fs-1">Mariiachi.com es un sitio web que tiene como propósito impulsar a músicos y bandas de México y el mundo.<br/><br/>Ingresa a <a href="https://mariiachi.com" target="__blank" className="text-mariachi">mariiachi.com</a> para encontrar músicos y bandas para tu evento y contáctalos directamente. <br/><br/>Si eres músico o tienes una banda, te invitamos a registrarte, no tiene ningún costo ni comisiones. </p>
            </div>

          </Col>
        </Row>
      </Container>

      <Contact />
      <Footer />
      {/* <Ad /> */}
    </div>
  );
}

export default Landing;
