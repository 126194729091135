import React, { Component } from "react";
import Typed from "react-typed";
import { Row, Col, Button, Card, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { motion, useViewportScroll, useTransform } from "framer-motion";

import { Link } from "react-router-dom";
import "../../assets/css/theme.css";
import "../../index.css";
import mano from "../../img/mano.png";
const imageStyle = {
  // position: "-webkit-sticky",
  // position: "fixed",
  // height: "",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  bottom: "0rem",
  left: "0.5rem",
  overflow: "visible",
  // padding: "1rem",
  boxSizing: "border-box",
  objectFit: "contain",

  // backgroundColor: "red",
};
const Banner = () => {
  const { scrollYProgress } = useViewportScroll();
  const xPosAnim = useTransform(
    scrollYProgress,
    [0, 0.05, 0.1],
    [250, 450, 720]
  );
  const yPosAnim = useTransform(scrollYProgress, [0, 0.05, 0.1], [0, 200, 510]);
  const scaleAnim = useTransform(scrollYProgress, [0, 0.8, 1], [0.1, 1, 1]);

  return (
    <div className="overflow-hidden banner" style={{ width: "100vw" }}>
      <div className="h-100 w-100 d-flex align-items-center justify-content-center">
        <div className="container d-flex align-items-center justify-content-center w-100 h-100">
          <Row className="w-100 h-100">
            <Col xs={12} sm={12} md={8} lg={7}>
              <div class="d-flex align-items-center pt-10 pb-8">
                <div class="header-text">
                  <div class="overflow-hidden">
                    <h3
                      class="text-white"
                      // data-zanim-xs='{"duration":2,"delay":0}'
                      style={{ fontWeight: "400" }}
                    >
                      Gasolinera Solidaridad,
                    </h3>
                    <br class="d-block d-sm-none" />
                    <h3
                      class="text-white"
                      // data-zanim-xs='{"duration":2,"delay":0}'
                      style={{ fontWeight: "400" }}
                    >
                      <Typed
                        strings={[
                          "excelente servicio",
                          "precios justos",
                          "gasolina de calidad",
                        ]}
                        typeSpeed={40}
                        backSpeed={50}
                        className="font-weight-bold"
                        loop
                      />
                    </h3>
                  </div>
                  <div class="overflow-hidden">
                    <span
                      class="text-uppercase lead text-white ls-1 mt-2 px-2"
                      // data-zanim-xs='{"duration":2,"delay":0.1}'
                      style={{ fontWeight: "500", backgroundColor: "#0552aa" }}
                    >
                      Nos dedicamos a ofrecerte<span className="ml-1"> </span>
                      <br />
                      <strong className="px-2">la mejor gasolina</strong>
                      <br />
                      <span className="pl-2">al </span>
                      <strong>mejor precio</strong> posible.
                    </span>
                  </div>

                  {/* <Link
                    to="/productos/#top"
                    className="btn btn-md btn-danger mt-1 px-3"
                    style={{
                      borderRadius: "10px",
                    }}
                  >
                    Consulta nuestros precios de gasolina
                  </Link> */}

                  {/* <a
                href="http://solidaridad.gasmanager.com"
                className="btn btn-md btn-secondary mt-3 px-3"
                style={{ borderRadius: "10px" }}
              >
                Facturación
              </a> */}
                </div>
              </div>
            </Col>

            <Col
              xs={12}
              sm={12}
              md={4}
              lg={5}
              style={{ height: "100%", minHeight: "30rem !important" }}
            >
              <motion.div
                className="h-100"
                style={{
                  ...imageStyle,
                  y: yPosAnim,
                  x: xPosAnim,
                }}
              >
                <img
                  src={mano}
                  alt="gasolina"
                  // className=" pl-3 pr-3"
                  style={{
                    // marginBottom: "-2rem",
                    height: "50%",
                    objectFit: "contain",
                    overflow: "show",
                    transform: "scaleX(-1)",
                  }}
                />
              </motion.div>
            </Col>
          </Row>
        </div>
      </div>

      <div
        className="w-100 mt-9 pt-0"
        style={{
          height: "20vw",
        }}
      >
        <div
          className="bgCurveBanner w-100 mt-0 pt-0 flex-column"
          style={{ height: "100%" }}
        ></div>
      </div>
    </div>
  );
};

export default Banner;
