import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Container,
  Carousel,
  Spinner,
  Modal,
} from "react-bootstrap";
import { db, storage } from "../../firebase";
import Iframe from "react-iframe";
import { HashLink as Link } from "react-router-hash-link";

import Continua from "../../img/continua.jpg";
import Prof from "../../img/profunda.jpg";
import Octa from "../../img/octane.jpg";

import infla from "../../img/inflallantas.png";

import lava from "../../img/lava_motores.jpg";
import para from "../../img/limpia-parabrisas.jpg";

const MainProd = () => {
  const [locs, setLocs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState([]);

  // const GetLocs = async () => {
  //   var locales = [];
  //   var arShow = [];

  //   setLoading(true);

  //   await db.collection("locales").where('active', '==', true).get().then(
  //     querySnapshot => {
  //       querySnapshot.forEach(docc => {
  //         locales.push({ ...docc.data(), id: docc.id });
  //         arShow.push(false);
  //       });
  //       setLocs(locales);
  //       setShow(arShow);
  //     }).catch(
  //       (err) => {
  //         console.log(`Encountered error: ${err}`);
  //       })

  //   setLoading(false);
  // }

  // useEffect(() => {
  //   GetLocs();
  //   console.log('collecting...')
  // }, []);

  var series = 0;

  // function handleOpen(s) {
  //   // s.preventDefault();
  //   var array = [];
  //   var ii = 0;
  //   for (ii = 0; ii < show.length; ii++) {
  //     if (ii == s) {
  //       array.push(true);
  //     } else {
  //       array.push(false);
  //     }
  //   }
  //   setShow(array);
  // }

  // function handleClose() {
  //   var f = 0;
  //   var ary = [];
  //   for (f = 0; f < show.length; f++) {
  //     ary.push(false);
  //   }
  //   setShow(ary);
  // };

  return (
    <Container>
      <br />
      <Row>
        {/* {(loading) ? (
          <Col lg={3} md={6} className="jcc mb-4">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
            <br />
          </Col>
        ) : (

          (locs.length == 0) ? (<Col lg={3} md={6} className="jcc"><h3>No hay locales disponibles por el momento</h3></Col>) : (

            locs.map(loc => {

              return <Col lg={3} md={6} className="jcc">
                <Card style={{ width: '18rem' }} className="mb-4">
                  <Carousel>
                    {loc.fotos.map(foto => {

                      return <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={foto}
                          alt="First slide"
                        />

                      </Carousel.Item>
                    })}
                  </Carousel>

                  <Card.Body className="fdc spb">
                    <div>
                      <small>
                        {loc.State}
                      </small>
                      <Card.Title>{loc.Nombre}</Card.Title>
                      <Card.Text className="mb-4">
                        {loc.Ubicacion}
                      </Card.Text>
                    </div>

                    <Button block variant="primary" onClick={() => handleOpen(locs.indexOf(loc))} >Ver más</Button>
                  </Card.Body>
                </Card>

                <Modal show={show[locs.indexOf(loc)]} onHide={handleClose} animation={false}>
                  <Modal.Header closeButton>
                    <h4>{loc.Nombre}</h4>
                  </Modal.Header>
                  <Modal.Body>{loc.Ubicacion}</Modal.Body>
                  <Iframe url={loc.locUrl}
                    width="100%"
                    height="30%"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative" />
                  <Container>
                    <p>Mt^2: {loc.mt2}</p>
                    <p>Baños: {loc.banos}</p>
                    <p>Medios Baños: {loc.medioBanos}</p>
                    <p>{loc.nota}</p>
                  </Container>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cerrar
                    </Button>
                    <Link className="btn btn-primary" to="/#contacto">
                      Solicitar información
                    </Link>
                  </Modal.Footer>
                </Modal>

              </Col>


            })))} */}

        <Col lg={4} md={6} className="fdr jcc">
          <Card style={{ width: "100%" }} className="mb-4">
            <img className="d-block w-100" src={Continua} alt="First slide" />
            <Card.Body className="fdc spb">
              <div>
                <small>{/* {loc.State} */}</small>
                <Card.Title>Bardahl Top Oil Limpieza Continua</Card.Title>
                <Card.Text className="mb-4">
                  <ul class="card-text">
                    <li>Reduce el consumo de gasolina</li>
                    <li>Mantiene el sistema funcionando eficientemente</li>
                    <li>Ruce emisiones contaminantes</li>
                  </ul>
                </Card.Text>
              </div>
            </Card.Body>
            {/* <div class="card-footer">
              <p class="card-text">MX $25.00</p>
              <p class="card-text">
                A MX $20.00 con <a href="http://www.swirvle.com">Swirvle</a>
              </p>
            </div> */}
          </Card>
        </Col>

        <Col lg={4} md={6} className="fdr jcc">
          <Card style={{ width: "100%" }} className="mb-4">
            <img className="d-block w-100" src={Prof} alt="First slide" />
            <Card.Body className="fdc spb">
              <div>
                <small>{/* {loc.State} */}</small>
                <Card.Title>Bardahl Top Oil Limpieza Profunda</Card.Title>
                <Card.Text className="mb-4">
                  <ul class="card-text">
                    <li>Limpia por completo los inyectores sucios</li>
                    <li>Evita la formación de impurezas y depósitos</li>
                    <li>Antioxidante</li>
                  </ul>
                </Card.Text>
              </div>
            </Card.Body>
            {/* <div class="card-footer">
              <p class="card-text">MX $60.00</p>
              <p class="card-text">
                A MX $35.00 con <a href="http://www.swirvle.com">Swirvle</a>
              </p>
            </div> */}
          </Card>
        </Col>

        <Col lg={4} md={6} className="fdr jcc">
          <Card style={{ width: "100%" }} className="mb-4">
            <img className="d-block w-100" src={Octa} alt="First slide" />
            <Card.Body className="fdc spb">
              <div>
                <small>{/* {loc.State} */}</small>
                <Card.Title>Bardahl Top Oil Octane Booster</Card.Title>
                <Card.Text className="mb-4">
                  <ul class="card-text">
                    <li>Ayuda a eliminar impurezas en la gasolina</li>
                    <li>Mejora la combustión y ahorra gasolina</li>
                    <li>Reduce las emisiones contaminantes</li>
                    <li>Alarga los períodos de afinación</li>
                  </ul>
                </Card.Text>
              </div>
            </Card.Body>
            {/* <div class="card-footer">
              <p class="card-text">MX $60.00</p>
              <p class="card-text">
                A MX $35.00 con <a href="http://www.swirvle.com">Swirvle</a>
              </p>
            </div> */}
          </Card>
        </Col>

        <Col lg={4} md={6} className="fdr jcc">
          <Card style={{ width: "100%" }} className="mb-4">
            <img className="d-block w-100" src={infla} alt="First slide" />
            <Card.Body className="fdc spb">
              <div>
                <small>{/* {loc.State} */}</small>
                <Card.Title>Bardahl inflallantas</Card.Title>
                <Card.Text className="mb-4">
                  <ul class="card-text">
                    <li>
                      Repara rápidamente una ponchadura provocada por clavos,
                      vidrios, pijas o incluso por falla de la válvula de la
                      llanta.
                    </li>
                    <li>Infla y sella llantas en tan solo 60 segundos.</li>
                    <li>Para todo tipo de llantas automotrices.</li>
                  </ul>
                </Card.Text>
              </div>
            </Card.Body>
            {/* <div class="card-footer">
              <p class="card-text">MX $70.00</p>
              <p class="card-text">
                A MX $45.00 con <a href="http://www.swirvle.com">Swirvle</a>
              </p>
            </div> */}
          </Card>
        </Col>

        <Col lg={4} md={6} className="fdr jcc">
          <Card style={{ width: "100%" }} className="mb-4">
            <img className="d-block w-100" src={lava} alt="First slide" />
            <Card.Body className="fdc spb">
              <div>
                <small>{/* {loc.State} */}</small>
                <Card.Title>Bardahl Lava Motores 2 en 1</Card.Title>
                <Card.Text className="mb-4">
                  <ul class="card-text">
                    <li>
                      No afecta los componentes eléctricos del motor ni partes
                      de hule, ni de plástico
                    </li>
                    <li>Elimina grasa acumulada en las mangueras.</li>
                    <li>
                      Deja una capa protectora antiestática repelente al polvo.
                    </li>
                    <li>
                      Deja el motor luciendo limpio y brillante, como nuevo.
                    </li>
                  </ul>
                </Card.Text>
              </div>
            </Card.Body>
            {/* <div class="card-footer">
              <p class="card-text">MX $95.00</p>
              <p class="card-text">
                A MX $55.00 con <a href="http://www.swirvle.com">Swirvle</a>
              </p>
            </div> */}
          </Card>
        </Col>

        <Col lg={4} md={6} className="fdr jcc">
          <Card style={{ width: "100%" }} className="mb-4">
            <img className="d-block w-100" src={para} alt="First slide" />
            <Card.Body className="fdc spb">
              <div>
                <small>{/* {loc.State} */}</small>
                <Card.Title>Bardahl Limpia Parabrisas</Card.Title>
                <Card.Text className="mb-4">
                  <ul class="card-text">
                    <li>
                      Facilita la eliminación de mugre e insectos adheridos al
                      parabrisas
                    </li>
                    <li>Alarga la vida de las plumas de los limpiadores</li>
                  </ul>
                </Card.Text>
              </div>
            </Card.Body>
            {/* <div class="card-footer">
              <p class="card-text">MX $40.00</p>
            </div> */}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MainProd;
