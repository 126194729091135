import React from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import corvette from "../../img/corvette.png";
import aditec from "../../img/aditec-logo-white.png";
import disp from "../../img/dispensario.png";

const imageStyle = {
  width: "100%",
  // position: "-webkit-sticky",
  // position: "fixed",
  // height: "",
  bottom: "0rem",
  left: "0.5rem",
  overflow: "visible",
  // padding: "1rem",
  boxSizing: "border-box",
  objectFit: "contain",
  // backgroundColor: "red",
};

function AnimatedSection() {
  // const reff1 = React.useRef()
  // const { scrollYProgress } = useElementScroll(reff1)
  const { scrollYProgress } = useViewportScroll();
  const xPosAnim = useTransform(scrollYProgress, [0, 0.6, 1], [-250, 10, 10]);
  const scaleAnim = useTransform(scrollYProgress, [0, 0.8, 1], [0.1, 1, 1]);
  // const centerdiv = document.getElementById('centerDiv');

  // scrollYProgress.onChange((x) => {
  //     centerdiv.scrollIntoView(true)
  // });
  return (
    <Container
      fluid
      className="landing-page-container2 d-flex flex-column pt-2 align-items-center justify-content-center"
      // ref={reff1}
      id="features"
    >
      <div className="justify-content-center align-items-center text-center">
        <div className="overflow-hidden">
          <h2
            className="font-weight-light"
            // data-zanim-xs='{"duration":"1.5","delay":0}'
          >
            Alto Performance
          </h2>
        </div>
        <div className="overflow-hidden">
          <p
            className="fs--1 text-uppercase text-black ls-0 mb-0"
            // data-zanim-xs='{"duration":"1.5","delay":0.1}'
          >
            La gasolina PEMEX ADITEC ® cuida de tu auto y te ofrece el máximo
            <br />
            performance para que tengas el mejor rendimiento posible.
          </p>
        </div>
        <div className="overflow-hidden">
          <hr
            className="hr-short border-black"
            // data-zanim-xs='{"duration":"1.5","delay":0.2}'
          />
        </div>
      </div>
      <Row className="align-items-center m-0">
        <Col
          md={6}
          sm={12}
          className="d-flex align-items-end justify-content-end text-center disp w-100 "
          style={{ height: "30vw" }}
        >
          {/* <img
            src={disp}
            alt="corvette"
            className=""
            style={{ height: "20rem" }}
          /> */}

          <motion.div
            style={{
              ...imageStyle,
              // y: yPosAnim,
              x: xPosAnim,
            }}
          >
            <img
              src={corvette}
              alt="corvette"
              // className=" pl-3 pr-3"
              style={{
                // marginBottom: "-2rem",
                height: "16vw",
                maxWidth: "100%",
                objectFit: "contain",
                overflow: "show",
              }}
            />
          </motion.div>
        </Col>

        <Col md={6} sm={12} className="mt-4">
          <div
            className="overflow-hidden banner2 w-100"
            style={{ borderRadius: "15px" }}
          >
            <Container className="h-100 w-100">
              <Row className="pb-lg-9 pb-xl-0 h-100">
                <Col
                  md={4}
                  lg={4}
                  xl={4}
                  xs={2}
                  sm={2}
                  className="pb-7 pb-xl-9 text-lg-right"
                />

                <Col
                  lg={8}
                  md={8}
                  xl={8}
                  xs={10}
                  sm={10}
                  className="d-flex align-items-start justify-content-start text-right"
                  style={{ zIndex: "99999999999999999" }}
                >
                  <div className="mt-7 ">
                    {/* <h1 className="text-white">Pemex ADITEC</h1> */}
                    <img
                      src={aditec}
                      alt="aditec"
                      className="mb-2"
                      style={{
                        height: "4rem",
                        width: "auto",
                        // zIndex: "9999999",
                      }}
                    />

                    <p
                      className="lead text-white"
                      style={{ fontWeight: "500" }}
                    >
                      Despachamos la mejor gasolina de México, Pemex ADITEC de
                      7tma generación con 7 agentes para el cuidado de tu auto.
                    </p>

                    <a
                      href="https://www.pemex.com/negocio/gasolineras/nuestros-productos/Aditec/files/index.aspx"
                      target="_blank"
                      className="btn btn-md btn-outline-danger mb-5 px-4"
                    >
                      Ver más
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>

      <div className="w-100 mt-4 pt-0" style={{ height: "7vw" }}>
        <div
          className="bgCurveCTop w-100 mt-0 pt-0 flex-column"
          style={{ height: "100%" }}
        ></div>
      </div>
    </Container>
  );
}
export default AnimatedSection;
// const envelopeStyle = {
//   width: "28rem",
//   height: "15rem",
//   scale: 1,
// //   position: "fixed",
//   top: "10rem",
//   left: "calc(50% - 14rem)",
//   boxShadow: `rgba(0, 0, 0, 0.5) 0px 0px 150px 10px`,
// };

// const frontfaceStyle = {
//   width: "100%",
//   height: "100%",
//   backgroundColor: "#debda1",
//   position: "absolute",
//   left: 0,
//   top: 0,
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
// };

// function Envelope({ children }) {
//   const [ffLayer, setFfLayer] = useState(0);
//   const { scrollYProgress } = useViewportScroll();
//   const scaleAnim = useTransform(scrollYProgress, [0, 0.5, 1], [1, 1.2, 0.8]);
//   const yPosAnim = useTransform(scrollYProgress, [10, 0.5, 1], [0, 100, 200]);
//   const xPosAnim = useTransform(scrollYProgress, [10, 0.5, 1], [0, 100, 200]);
//   const zRotAnim = useTransform(scrollYProgress, [0, 0.5, 1], [0, 3, 0]);

//   scrollYProgress.onChange((x) => {
//     setFfLayer(x > 0.4 ? -1 : 0);
//   });

//   return (
//     <motion.div
//       style={{
//         ...envelopeStyle,
//         scale: scaleAnim,
//         y: yPosAnim,
//         x: xPosAnim,
//         rotateZ: zRotAnim,
//       }}
//     >
//       {children}
//       <div style={{ ...frontfaceStyle, zIndex: ffLayer }}>
//         <button onClick={() => window.scrollTo(0, 1500)}>Open Me</button>
//       </div>
//     </motion.div>
//   );
// }

// const letterSceneStyle = {
//   height: "100%",
// };

// export default function LetterScene() {
//   return (
//     <div style={letterSceneStyle}>
//       <Envelope>
//         <Letter />
//       </Envelope>
//     </div>
//   );
// }
