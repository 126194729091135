import React, { Component } from "react";
import Typed from "react-typed";
import { Row, Col, Button, Card, Container } from "react-bootstrap";
import Iframe from "react-iframe";

import "../../index.css";
const Contact = () => {
  return (
    <Container fluid className="text-center bg-light" id="contacto">
      <br />
      <Container>
        <Row className="justify-content-center align-items-center ">
          <Col lg={6} className="">
            {/* <h4 className="mb-4">Contacto</h4>
            <div className="fdr jcc mb-3">
              <a href="https://wa.me/message/4U6JST5UXMNFJ1" target="_blank" className="btn-whatsapp">Whatsapp</a>
            </div>
            <p>Matriz: Av. Luis Donaldo Colosio Murrieta 1571, Mirasol 1o., 64100 Monterrey, N.L.</p> */}
            <div className="text-left mb-5 mb-lg-0 d-flex flex-column justify-content-around">
              <div className="row">
                <div className="col-12">
                  <h5 className="mb-3">Conecta con nosotros</h5>
                </div>

                <div
                  className="col-auto mb-2 mb-sm-0"
                  style={{ width: "190px" }}
                >
                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col px-2">
                      {/* <p className="mb-1 text-700">
                      <strong>Reign Studio</strong>
                    </p>
                    <p className="mb-0 text-600">
                      590, Castro St,
                      <br />
                      United States
                    </p> */}
                      <a
                        href="https://www.facebook.com/115670323174143/"
                        target="__blank"
                        className="text-600"
                      >
                        Facebook
                      </a>
                      <br />
                      <a
                        href="https://www.instagram.com/gasolidaridad/"
                        target="__blank"
                        className="text-600"
                      >
                        Instagram
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-auto" style={{ width: "205px" }}>
                  <div className="row mb-2 mb-sm-1 px-4">
                    {/* <div className="col-1"></div> */}
                    <div className="col px-2">
                      {/* <a className="text-600" href="tel:526853300">
                      526 853 300{" "}
                    </a>
                    <br />
                    <a className="text-600" href="tel:526853300">
                      478 484 500
                    </a> */}
                      <a
                        href="https://wa.me/message/4U6JST5UXMNFJ1"
                        className="text-600"
                      >
                        81 1690 9429
                      </a>
                      <br />
                      {/* <a
                        href="mailto: tropezcapital@gmail.com"
                        className="text-600"
                      >
                        tropezcapital@gmail.com
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="marker-content py-3">
                <h5>Suc. Matriz</h5>
                <p>
                  Av. Luis Donaldo Colosio Murrieta 1571, Barrio San Luis,
                  <br /> 64100, Monterrey, N.L. MX.
                </p>
              </div>
            </div>
          </Col>

          <Col lg={6} className="fdc jcc">
            <Iframe
              url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.855163593571!2d-100.38945674911749!3d25.775347114245342!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662913ddccfb363%3A0x300394e65405bae2!2sGasolinera%20solidaridad%207842!5e0!3m2!1ses!2smx!4v1590079324936!5m2!1ses!2smx"
              width="100%"
              height="200px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"
            />

            <Iframe
              url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3138.63486329924!2d-100.40228841143815!3d25.77482189771103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866290da049b3fa3%3A0x74049dd5667995d6!2sGasolinera%20Solidaridad!5e0!3m2!1ses!2smx!4v1590079238263!5m2!1ses!2smx"
              width="100%"
              height="200px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"
            />
          </Col>
        </Row>
      </Container>
      <br />
    </Container>
  );
};

export default Contact;
