import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import logo from "../img/logo_transp.png";
import { Link, useHistory } from "react-router-dom";

const Footer = () => {
  let history = useHistory();

  function clickLoc() {
    history.push("/locales");
  }

  function clickCas() {
    history.push("/casas");
  }

  function clickTer() {
    history.push("/terrenos");
  }

  return (
    // <Container fluid className="footer mb-5">
    //   <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
    //     <Col lg={7} className="pb-7 pb-xl-9 text-center bgLogo">
    //     <img src={logo} className="logoFooter" />
    //     </Col>

    //     <Col lg={5} className="">
    //       <Row>

    //     <Col lg={6} className="pb-7 pb-xl-9 text-center text-lg-left fdc">
    //         <h5 className="text-white">
    //         Social
    //         </h5>
    //         <a href="https://www.facebook.com/gasolinerasolidaridadoficial" target="__blank" className="text-white">Facebook</a>
    //         {/* <a href="https://www.instagram.com/tropez_capital/" target="__blank" className="text-white">Instagram</a> */}
    //     </Col>

    //     <Col lg={6} className="pb-7 pb-xl-9 text-center text-lg-left">
    //         <h5 className="text-white">Contacto</h5>
    //         <div>
    //           <a href="https://wa.me/message/4U6JST5UXMNFJ1" className="text-white">81 1690 9429</a>
    //         </div>

    //         {/* <a href="mailto: tropezcapital@gmail.com" className="text-white">tropezcapital@gmail.com</a> */}
    //         {/* <p className="text-white">Av. Luis Donaldo Colosio Murrieta 1571, Mirasol 1o., 64100 Monterrey, N.L.</p> */}
    //     </Col>
    //       </Row>

    //     </Col>

    //   </Row>
    //   </Container>
    <section
      className="py-3 px-3 px-lg-0"
      style={{ backgroundColor: "#f0f0f0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-3 pl-md-4 mb-4">
            <img src={logo} style={{ height: "10rem", width: "auto" }} />
          </div>

          <div className="col-lg-4 pr-lg-4 mb-4 mb-lg-0">
            <h5 className="mb-3">nuestra misión</h5>
            <p className="text-700">
              Ofrecerte la mejor gasolina posible y el mejor precio. Trabajamos
              bajo los valores de la honestidad y responsabilidad.
            </p>
          </div>

          <div className="col-lg-5">
            <div className="row">
              <div className="col-lg-2 col-md-3 col-sm-12 ml-lg-4 mb-4 mb-lg-0">
                <h5 className=" mb-3">social</h5>
                <ul className="list-unstyled mb-0">
                  <li className="mb-1">
                    <a
                      className="text-700 "
                      href="https://www.facebook.com/115670323174143/"
                      target="__blank"
                    >
                      Facebook
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      className="text-700 "
                      href="https://www.instagram.com/gasolidaridad/"
                      target="__blank"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-6 col-md-3 col-sm-12 ml-lg-4 mb-4 mb-lg-0">
                <h5 className=" mb-3">contacto</h5>
                <ul className="list-unstyled mb-0">
                  <li className="mb-1">
                    <a
                      href="https://wa.me/message/4U6JST5UXMNFJ1"
                      className="text-700 "
                    >
                      81 1690 9429
                    </a>
                  </li>
                  {/* <li className="mb-1">
                    <a
                      href="mailto: tropezcapital@gmail.com"
                      className="text-700 hover-color-white"
                    >
                      tropezcapital@gmail.com
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
