import logo from "./logo.svg";
import "./App.css";

import Landing from "./layouts/landing";
function App() {
  return (
    <div className="text-poppins">
      <Landing />
    </div>
  );
}

export default App;
