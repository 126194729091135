import React, { Component } from "react";
import Typed from "react-typed";
import { Row, Col, Button, Card, Container, Carousel } from "react-bootstrap";

import comment1 from "../../img/comment1.png";
import comment2 from "../../img/comment2.png";
import comment3 from "../../img/comment3.png";

const Comments = () => {
  return (
    <Container
      fluid
      className="text-center d-flex flex-column pt-2 align-items-center justify-content-center"
      style={{
        position: "relative",
        overflowX: "hidden",
        backgroundColor: "#0552aa",
        color: "white",
      }}
    >
      <Container className="mt-5">
        <br />
        <br />
        <br />
        <Row>
          <Col
            lg={6}
            className="carr shadow d-flex align-items-center justify-content-center"
            style={{
              borderRadius: "20px",
              backgroundColor: "white",
              marginBottom: "1rem",
              color: "white",
              zIndex: "2",
            }}
          >
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={comment1}
                  alt="comentario"
                />
                {/* <Carousel.Caption>
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
              </Carousel.Caption> */}
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={comment2}
                  alt="comentario"
                />

                {/* <Carousel.Caption>
                <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </Carousel.Caption> */}
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={comment3}
                  alt="comentario"
                />

                {/* <Carousel.Caption>
                <h3>Third slide label</h3>
                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
              </Carousel.Caption> */}
              </Carousel.Item>
            </Carousel>
          </Col>

          <Col
            lg={6}
            className="text-left d-flex fdc justify-content-start"
            // style={{ backgroundColor: "red" }}
          >
            <h3 className="mt-4 font-weight-light text-white">
              100% probados y comprobados
            </h3>

            <p className="text-left">
              Grupo Gasolinero Solidaridad está basado en el trabajo honesto y
              en precios JUSTOS. Y si no es suficiente escucharlo de nosotros,
              checa lo que dicen nuestros clientes en:{" "}
              <a
                style={{ color: "#f57426" }}
                href="https://www.google.com/search?q=grupo+gasolinero+solidaridad&oq=grupo&aqs=chrome.0.69i59j69i57j0j46j69i60l4.1661j0j4&sourceid=chrome&ie=UTF-8#lrd=0x866290da049b3fa3:0x74049dd5667995d6,1,,,"
                target="_blank"
              >
                Google
              </a>{" "}
              y{" "}
              <a
                style={{ color: "#f57426" }}
                href="https://www.facebook.com/115670323174143/"
                target="_blank"
              >
                Facebook
              </a>
              .
            </p>
          </Col>
        </Row>
      </Container>

      <div
        className=" mt-0 pt-0"
        style={{
          width: "100vw",
          position: "relative",
          height: "19rem",
          backgroundColor: "white",
        }}
      >
        <div
          className="bgCurveLTop w-100 mt-0 pt-0 flex-column"
          style={{ height: "100%" }}
        ></div>
      </div>
    </Container>
  );
};

export default Comments;
