import React, { Component } from "react";
import Typed from "react-typed";
import { Row, Col, Button, Card, Container } from "react-bootstrap";
import "../../assets/css/theme.css";

import likeicon from "../../assets/img/line-icons/like.svg";
import caricon from "../../assets/img/line-icons/car-1.svg";
import piggyicon from "../../assets/img/line-icons/piggy-bank.svg";
import ReactPlayer from "react-player";

const Intro = () => {
  return (
    <Container fluid className="text-center py-4">

<Row className="d-flex align-items-center justify-content-center">
  <Col xs={12} lg={9}>
         <div
        className="shadow w-100 px-1 py-2 mb-3"
        style={{
          borderRadius: "15px",
          height: "70vw",
          maxHeight: "36rem",
          backgroundColor: "black",
        }}
      >
        <ReactPlayer
          controls={true}
          url={"https://youtu.be/dnvIajQ8--A"}
          className="w-100 h-100 br-15"
        />
      </div>
  </Col>

</Row>
 

      <Container>
        <br />

        <div className="row">
          <div
            className="col-sm-6 col-lg-4 mb-3"
          // data-zanim-timeline="{}"
          // data-zanim-trigger="scroll"
          >
            <div className="overflow-hidden">
              <div className="service-item p-3 p-md-4 h-100">
                <div className="overflow-hidden">
                  <div
                    className="px-4"
                  // data-zanim-xs='{"duration":"1.5","delay":"0.3"}'
                  >
                    <img
                      className="service-icon"
                      style={{ color: "black" }}
                      src={caricon}
                      alt=""
                    />
                  </div>
                </div>
                <div className="overflow-hidden">
                  <h5
                    className="font-weight-normal  mb-2"
                  // data-zanim-xs='{"duration":"1.5","delay":"0.4"}'
                  >
                    Calidad
                  </h5>
                </div>
                <div className="overflow-hidden">
                  <p
                    className="font-weight-normal"
                  // data-zanim-xs='{"duration":"1.5","delay":"0.5"}'
                  >
                    Litros completos y gasolina de calidad PEMEX ADITEC,{" "}
                    <br className="d-block d-sm-none" /> cuida de tu auto y
                    obtén el mejor rendimiento.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-sm-6 col-lg-4 mb-3"
          // data-zanim-timeline="{}"
          // data-zanim-trigger="scroll"
          >
            <div className="overflow-hidden">
              <div className="service-item p-3 p-md-4 h-100">
                <div className="overflow-hidden">
                  <div
                    className="px-4"
                    data-zanim-xs='{"duration":"1.5","delay":"0.3"}'
                  >
                    <img className="service-icon" src={likeicon} alt="" />
                  </div>
                </div>
                <div className="overflow-hidden">
                  <h5
                    className="font-weight-normal  mb-2"
                    data-zanim-xs='{"duration":"1.5","delay":"0.4"}'
                  >
                    Confianza &amp; Honestidad
                  </h5>
                </div>
                <div className="overflow-hidden">
                  <p
                    className="font-weight-normal"
                    data-zanim-xs='{"duration":"1.5","delay":"0.5"}'
                  >
                    Litros completos, la única manera de trabajar
                    <br className="d-block d-sm-none" /> es siendo honestos.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-sm-6 col-lg-4 mb-3"
          // data-zanim-timeline="{}"
          // data-zanim-trigger="scroll"
          >
            <div className="overflow-hidden">
              <div className="service-item p-3 p-md-4 h-100">
                <div className="overflow-hidden">
                  <div
                    className="px-4"
                    data-zanim-xs='{"duration":"1.5","delay":"0.4"}'
                  >
                    <img className="service-icon" src={piggyicon} alt="" />
                  </div>
                </div>
                <div className="overflow-hidden">
                  <h5
                    className="font-weight-normal  mb-2"
                    data-zanim-xs='{"duration":"1.5","delay":"0.5"}'
                  >
                    Buen Precio
                  </h5>
                </div>
                <div className="overflow-hidden">
                  <p
                    className="font-weight-normal"
                    data-zanim-xs='{"duration":"1.5","delay":"0.6000000000000001"}'
                  >
                    Ademas de precios justos en gasolina, te ofrecemos
                    <br className="d-block d-sm-none" /> productos adicionales
                    al mejor precio.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default Intro;
