import React, { Component, useState, useEffect } from "react";
import { Navbar, Nav, Button, NavDropdown, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import "../../../src/index.css";
import logo from "../../img/logo-rect.png";
import { motion, useViewportScroll, useTransform } from "framer-motion";

import "../../assets/css/theme.css";

function Navtab() {
  let history = useHistory();
  const { scrollYProgress } = useViewportScroll();
  const [transp, setTransp] = useState(0);

  const [scrollTop, setScrollTop] = useState();
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
      if (scrollYProgress.current < 0.039) {
        setTransp(0.2);
      } else {
        setTransp(0.92);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    // <Navbar bg="light" expand="lg" className="fixed-top">
    //     <Navbar.Brand ><Link className="navBrand" to="/">Gasolinera Solidaridad<strong className="textOr">.</strong></Link></Navbar.Brand>
    //     <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //     <Navbar.Collapse id="basic-navbar-nav">
    //         <Nav className="mr-auto">
    //             <Nav.Link className="navLink" onClick={clickProd}>Productos</Nav.Link>
    //             <Nav.Link ><Link to="/#contacto" className="navLink">Contacto</Link></Nav.Link>

    //         </Nav>
    //         <a className="navLink" href="https://tropezcapital.com" target="_blank">Renta un local</a>
    //         {/* <a href="https://wa.me/message/4U6JST5UXMNFJ1">81 1690 9429</a> */}
    //     </Navbar.Collapse>
    // </Navbar>

    <Navbar
      expand="lg"
      onToggle={(resp) => {
        if (resp === true) {
          setTransp(0.92);
        } else {
          if (scrollYProgress.current < 0.039) {
            setTransp(0.1);
          } else {
            setTransp(0.92);
          }
        }
      }}
      style={{
        backgroundColor: `rgba(255, 255, 255,${transp} )`,
        transition: "ease-in-out, 0.5s",
        // paddingBottom: "0px",
        zIndex: "999999999999999999",
      }}
      className="navbar-light fixed-top p-0"
    >
      <div className="container px-3">
        <Navbar.Brand>
          <Link className="navBrand" to="/">
            <img
              src={logo}
              style={{
                height: "4rem",
                width: "auto",
                padding: "0px",
                marginRight: "0.7rem",
                // marginTop: "-0.5rem",
              }}
            />
            {/* <span className="text-warning font-weight-bold">GASOLINERA</span> */}
            {/* Gasolinera Solidaridad<strong className="textOr">.</strong> */}
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" className="mb-2">
          <Nav className="navbar-nav ml-auto">
            <Link
              className="nav-item nav-link"
              style={{
                marginTop: "1.4rem",
                fontSize: 13,
                fontWeight: "500",
                transition: "ease-in-out 0.3s !important",
              }}
              to="/productos/#top"
            >
              Productos
            </Link>

            <Nav.Link className="nav-item">
              <Link
                to="/#contacto"
                className="nav-link"
                style={{
                  fontSize: 13,
                  fontWeight: "500",
                  transition: "ease-in-out 0.3s",
                }}
              >
                Contacto
              </Link>
            </Nav.Link>

            {/* <Nav.Link className="nav-item"> */}
            {/* <a
              href="https://wa.me/message/4U6JST5UXMNFJ1"
              className="nav-item"
              target="__blank"
              style={{
                fontSize: 13,
                fontWeight: "500",
                transition: "ease-in-out 0.3s",
                textDecoration: "none",
                marginTop: "0.4rem",
              }}
            >
              <p className="nav-link">81 1690 9429</p>
            </a> */}
            {/* </Nav.Link> */}

            {/* <Nav.Link className="nav-item"> */}
            {/* <a
              href="http://solidaridad.gasmanager.com"
              className="nav-item"
              target="__blank"
              style={{
                fontSize: 13,
                fontWeight: "500",
                transition: "ease-in-out 0.3s",
                textDecoration: "none",
                marginTop: "0.4rem",
              }}
            >
              <p className="nav-link">facturación</p>
            </a> */}
            {/* </Nav.Link> */}
            {/* <a
              href="https://wa.me/message/4U6JST5UXMNFJ1"
              target="__blank"
              className="nav-item nav-link"
              style={{
                fontSize: 13,
                //   color: "#FFF",
                // marginTop: "1.35rem",
                fontWeight: "500",
                transition: "ease-in-out 0.3s",
              }}
            >
              81 1690 9429
            </a> */}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default Navtab;
