import React from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import ggphone from "../../img/ggsphone.png";
import "../../App.css"

const imageStyle = {
  width: "100%",
  // position: "-webkit-sticky",
  // position: "fixed",
  // height: "100rem",
  top: "0.5rem",
  left: "0.5rem",
  right: "0.5rem",
  overflow: "hidden",
  padding: "1rem",
  boxSizing: "border-box",
};

function LealtadSection() {
  // const reff1 = React.useRef()
  // const { scrollYProgress } = useElementScroll(reff1)
  const { scrollYProgress } = useViewportScroll();
  // const xPosAnim = useTransform(scrollYProgress, [0, 0.8, 1], [-250, 0, 0]);
  const scaleAnim = useTransform(scrollYProgress, [0, 0.7, 1], [0.05, 0.8, 1]);
  // const centerdiv = document.getElementById('centerDiv');

  // scrollYProgress.onChange((x) => {
  //     centerdiv.scrollIntoView(true)
  // });
  return (
    <Container
      fluid
      className="d-flex flex-column mb-4"
      // ref={reff1}
      id="swirvle"
      style={{ overflowX: "hidden" }}
    >
      <div
        className="justify-content-center align-items-center text-center"
      // style={{ marginTop: "-4rem", zIndex: "999999" }}
      >
        <div className="overflow-hidden">
          <h2
            className="font-weight-light"
          // data-zanim-xs='{"duration":"1.5","delay":0}'
          >
            Sé cliente frecuente
          </h2>
        </div>
        <div className="overflow-hidden">
          <p
            className="fs--1 text-uppercase text-black ls-0 mb-0"
          // data-zanim-xs='{"duration":"1.5","delay":0.1}'
          >
            Participa en promociones exclusivas, podrás llevarte desde
            <br />
            descuentos hasta productos o gasolina gratis.
          </p>
        </div>
        <div className="overflow-hidden">
          <hr
            className="hr-short border-black"
            data-zanim-xs='{"duration":"1.5","delay":0.2}'
          />
        </div>
      </div>
      <Row className="align-items-center m-0">
        <Col
          md={6}
          sm={12}
          className="align-items-center justify-content-center text-center mt-4 p-4"
        >
          <h3 className="font-weight-light" style={{ display: "inline" }}>
            Tarjeta cliete frecuente digital <br /> con{" "}
            <h3
              className="text-swirvle"
            >
              swirvle
            </h3>
            <small>®</small>
          </h3>

          <p className="lead">
            Regístrate en{" "}
            <a
              href="https://swirvle.com"
              target="_blank"
              style={{ fontWeight: "450" }}
            >
              Swirvle
            </a>{" "}
            y muestra tu código QR al cargar con nosotros, para participar en
            promociones exclusivas que van desde descuentos, hasta productos
            gratis.
          </p>

          <a
            href="https://www.swirvle.com/register/"
            target="_blank"
            className="btn btn-md btn-outline-primary mb-5 px-4"
          >
            Regístrate Aquí
          </a>
        </Col>

        <Col
          md={6}
          sm={12}
          className="align-items-center justify-content-center text-center respad prodBanner"
        >
          <Container>
            <motion.div
              style={{
                ...imageStyle,
                // y: yPosAnim,
                // x: xPosAnim,
                scale: scaleAnim,
              }}
            >
              <img src={ggphone} alt="corvette" className="img2 pl-3 pr-3" />
            </motion.div>
          </Container>
        </Col>
      </Row>
      {/*       
      <br />
      <br />
      <Row className="align-items-center m-0">
        <Col
          md={6}
          sm={12}
          className="align-items-center justify-content-center text-center respad"
        >
          <h1>¿Por qué usar Swirvle?</h1>
          <p>
            Participa en promociones exclusivas, entérate primero sobre
            promociones y todo sin complicar tu experiencia, una sola cuenta
            para todos los negocios.
          </p>
        </Col>

        <Col
          md={6}
          sm={12}
          className="align-items-center justify-content-center text-center respad mt-4"
        >
          <motion.div
            style={{
              ...imageStyle,
              // y: yPosAnim,
              // x: xPosAnim,
              scale: scaleAnim,
            }}
          >
            <img src={iphone2} alt="iphone2" className="img1 pl-3 pr-3" />
          </motion.div>
        </Col>
      </Row> */}

      <br />
      <div>


      </div>
    </Container>
  );
}
export default LealtadSection;
// const envelopeStyle = {
//   width: "28rem",
//   height: "15rem",
//   scale: 1,
// //   position: "fixed",
//   top: "10rem",
//   left: "calc(50% - 14rem)",
//   boxShadow: `rgba(0, 0, 0, 0.5) 0px 0px 150px 10px`,
// };

// const frontfaceStyle = {
//   width: "100%",
//   height: "100%",
//   backgroundColor: "#debda1",
//   position: "absolute",
//   left: 0,
//   top: 0,
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
// };

// function Envelope({ children }) {
//   const [ffLayer, setFfLayer] = useState(0);
//   const { scrollYProgress } = useViewportScroll();
//   const scaleAnim = useTransform(scrollYProgress, [0, 0.5, 1], [1, 1.2, 0.8]);
//   const yPosAnim = useTransform(scrollYProgress, [10, 0.5, 1], [0, 100, 200]);
//   const xPosAnim = useTransform(scrollYProgress, [10, 0.5, 1], [0, 100, 200]);
//   const zRotAnim = useTransform(scrollYProgress, [0, 0.5, 1], [0, 3, 0]);

//   scrollYProgress.onChange((x) => {
//     setFfLayer(x > 0.4 ? -1 : 0);
//   });

//   return (
//     <motion.div
//       style={{
//         ...envelopeStyle,
//         scale: scaleAnim,
//         y: yPosAnim,
//         x: xPosAnim,
//         rotateZ: zRotAnim,
//       }}
//     >
//       {children}
//       <div style={{ ...frontfaceStyle, zIndex: ffLayer }}>
//         <button onClick={() => window.scrollTo(0, 1500)}>Open Me</button>
//       </div>
//     </motion.div>
//   );
// }

// const letterSceneStyle = {
//   height: "100%",
// };

// export default function LetterScene() {
//   return (
//     <div style={letterSceneStyle}>
//       <Envelope>
//         <Letter />
//       </Envelope>
//     </div>
//   );
// }
