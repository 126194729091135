import React from "react";

import Navtab from "../components/Navbar/navbar";
import Footer from "../components/Footer.js";
import ProductosBanner from "../components/productos/productosBanner.js";
import MainProd from "../components/productos/mainProd";
import Ad from "../components/ad.js";

function Productos() {
  return (
    <div>
      <Navtab />
      <ProductosBanner />
      <MainProd />
      <Footer />
      {/* <Ad /> */}
    </div>
  );
}

export default Productos;
